.row .toggle {
  margin-left: 30%;
}
.toggle input[type="checkbox"] {
  visibility: hidden;
}
.toggle input[type="checkbox"]:checked + label {
  transform: rotate(360deg);
  background-color: #1eb606;
}
.toggle input[type="checkbox"]:checked + label:before {
  transform: translateX(30px);
  background-color: #000;
}

.toggle label {
  display: flex;
  width: 60px;
  height: 30px;
  border: 2px solid;
  border-radius: 99em;
  position: relative;
  transition: transform 0.75s ease-in-out;
  transform-origin: 50% 50%;
  cursor: pointer;
  background-color: #cc0000;
}
.toggle label:before {
  transition: transform 0.75s ease;
  transition-delay: 0.5s;
  content: "";
  display: block;
  position: absolute;
  width: 18px;
  height: 18px;
  background-color: #000;
  border-radius: 50%;
  top: 3px;
  left: 3px;
}
