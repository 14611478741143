.blink-bg {
  color: #fff;
  background-color: #9c27b0;
  padding: 10px;
  display: inline-block;
  border-radius: 5px;
  animation: blinkingBackground 0.8s infinite;
}
@keyframes blinkingBackground {
  0% {
    background-color: white;
  }

  25% {
    background-color: #9c27b0;
  }
  50% {
    background-color: white;
  }

  75% {
    background-color: #9c27b0;
  }
  100% {
    background-color: white;
  }
}
