/* hide radio buttons */

input[name*="star"] {
  display: inline-block;
  width: 0;
  opacity: 0;
  margin-left: -4%;
}

/* hide source svg */

.star-source {
  margin-top: -10%;
  width: 0;
  height: 0;
  visibility: hidden;
}

/* set initial color to transparent so fill is empty*/

.star {
  color: transparent;
  transition: color 0.2s ease-in-out;
  width: 1.6rem;
  height: 1.6rem;
}

/* set direction to row-reverse so 5th star is at the end and ~ can be used to fill all sibling stars that precede last starred element*/

.star-container {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
}

label:hover ~ label .star,
svg.star:hover,
input[name*="star"]:focus ~ label .star,
input[name*="star"]:checked ~ label .star {
  color: #dc3545;
}

input[name*="star"]:checked + label .star {
  animation: starred 0.5s;
}

input[name*="star"]:checked + label {
  animation: scaleup 1s;
}

@keyframes scaleup {
  from {
    transform: scale(1.2);
  }
  to {
    transform: scale(1);
  }
}

@keyframes starred {
  from {
    color: #600040;
  }
  to {
    color: #dc3545;
  }
}
