.pos {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100; /*
  background-color: rgba(0, 0, 0, 0.8);
 */
  backdrop-filter: blur(8px);
  width: 100vw;
  height: 100vh;
  max-height: 100vh;
  font-size: 12px;
}
.pos table tbody {
  cursor: pointer;
}

.pos .content .card {
  cursor: pointer;
}

.itemler {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: minmax(min-content, max-content);
  grid-gap: 10px;
  max-height: 60vh;
  overflow-y: scroll;
  grid-template-rows: masonry;
}
.itemler h5 {
  font-size: 1.1vw;
}
.addOptions {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.pos .content {
  background-color: white;
  position: absolute;
  width: 100vw;
  height: 100vh;
  border: 2px solid black;
}
.pos .pos-menu {
  display: grid;
  grid-auto-rows: auto;
  height: 100vh;
}
.pos-menu > div:hover :not(.btn) {
  opacity: 0.5;
}
.pos-menu .menu-item {
  border: 1px solid rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-weight: bold;
  font-size: 2.5vw;
  font-weight: bold;
}

.pos .categories {
  display: grid;
  grid-template-columns: 45% 45%;
  font-size: 1vw;
  grid-gap: 5%;
}
.categories .category-item {
  background-color: #444;
  color: #fff;
  display: flex;
  justify-content: center;
  font-size: 1.1vw;
  align-items: center;
  text-align: center;
}
* {
  margin: 0;
  padding: 0;
}
.not-ekle {
  width: 100%;
}
.numbers {
  margin-top: 5%;
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}
.number-item {
  cursor: pointer;
  padding: 1.3vw;
  font-size: 1.5vw;
  font-weight: bold;
  width: fit-content;
  height: fit-content;
  border: 1px solid black;
  border-radius: 10%;
}
.adisyon {
  max-height: 80vh;
  overflow-y: scroll;
  overflow-x: hidden;
}
hr.line {
  border-top: 1px solid black;
  margin: 1% auto;
}
.mi-r svg {
  color: red;
}

.blurred {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 150;
  width: 100%;
  height: 100%;
  padding-top: 100px;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.6);
  -webkit-transition: 0.5s;
  overflow: auto;
  transition: all 0.3s linear;
}

.payment-menu {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 200;
  width: 100%;
  height: 100%;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.payment-container {
  z-index: 250;
  width: 40%;
  height: 40%;
  background-color: white;
  align-items: center;
  justify-content: center;
  align-content: center;
  padding: 20px;
}
.payment-container p {
  font-size: 1.5vw;
}
.discount-container > div {
  border-bottom: 1px solid black;
  cursor: pointer;
}
.discount-container p {
  font-size: 1vw;
}
.discount-container {
  z-index: 250;
  width: 12%;
  height: 40%;
  background-color: white;
  align-items: center;
  justify-content: center;
  align-content: center;
  padding: 20px;
}
.payment-menu-input {
  width: 50%;
  float: right;
  text-align: right;
  padding-right: 5px;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
}

.meal-options-menu {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 200;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
}
.meal-options-container {
  z-index: 250;
  width: 100%;
  height: fit-content;
  background-color: white;
  overflow: hidden;
}
.meal-options-container .row > div:not(.col-1) {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: 100px;
}

.meal-options-container .row > div > div {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  font-size: 1.8vw;
  font-weight: bold;
  text-align: center;
}
.meal-options-container .row .col-1 {
  display: grid;
  grid-auto-rows: 120px;
}
.meal-options-container .card {
  cursor: pointer;
}
.move-tables-menu {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 200;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
}
.move-tables-container {
  z-index: 250;
  width: 80%;
  height: 80%;
  background-color: white;
  align-items: center;
  justify-content: center;
  align-content: center;
  padding: 20px;
  overflow: scroll;
}
.move-tables {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1%;
}
.move-tables > div {
  border: 1px solid black;
  background-color: #555;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 5%;
  width: 15%;
  height: 20%;
}
.cancel-menu {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 200;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
}
.cancel-menu-item {
  cursor: pointer;
  z-index: 300;
  width: 15vw;
  height: 15vh;
  border: 1px solid black;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
}
@media print {
  .ticket {
    display: block;
  }
}

.payment-numbers {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(5, 1fr);
  gap: 2%;
}
.payment-numbers > div {
  border: 1px solid black;
  background-color: #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 10%;
}
.payment-numbers > div:nth-child(4n) {
  background-color: #aaa;
}
