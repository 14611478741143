.detail-image {
  width: 100vw;
  height: 100%;
}

.detail-image img {
  width: 100%;
  object-fit: cover;
  object-position: 50% 50%;
  max-height: 40vh;
}

.meal-detail .input-group {
  width: 80vw;
  height: 15vh;
  margin: 0 auto 10px auto;
}
#meal-note textarea:focus {
  outline: none !important;
  border-color: black;
  box-shadow: none;
  z-index: 0;
}

.meal-detail .back-icon {
  position: absolute;
  margin: 15px;
  z-index: 32;
  font-size: 35px;
  font-weight: bold;
}

.options {
  width: 80vw;
  margin: 5% auto;
}
.options p {
  font-weight: 600;
}
.box {
  display: flex;
  align-items: center;
  user-select: none;
  margin-top: 1.8%;
}

.box label {
  all: unset;
  color: #4d4d4d;
  position: absolute;
  z-index: 10;
  padding-left: 50px;
  cursor: pointer;
}
.box input {
  opacity: 0;
  visibility: hidden;
  position: absolute;
}
.box input:checked ~ .check {
  border-color: rgb(230, 3, 75);
  box-shadow: 0px 0px 0px 15px rgb(230, 3, 75) inset;
}
.box input:checked ~ .check::after {
  opacity: 1;
  transform: scale(1);
}
.box .check {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 100px;
  background-color: #fff;
  border: 2px solid rgb(230, 3, 75);
  border: 2px solid rgb(230, 3, 75);
  box-shadow: 0px 0px 0px 0px inset;
  transition: all 0.15s cubic-bezier(0, 1.05, 0.72, 1.07);
}
.box .check::after {
  content: "";
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 4;
  position: absolute;
  transform: scale(0);
  background-size: 50%;
  background-image: url("https://s6.picofile.com/d/8392306668/bacc888c-bed7-41a9-bf24-f6ff0718f471/checkmark.svg");
  background-repeat: no-repeat;
  background-position: center;
  transition-delay: 0.2s !important;
  transition: all 0.25s cubic-bezier(0, 1.05, 0.72, 1.07);
}
