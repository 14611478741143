.nav {
  position: fixed;
  bottom: 0;
  width: 100vw;
  max-width: 100vw;
  height: 14vw;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  display: flex;
  overflow-x: hidden;
}

.nav__link {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  min-width: 50px;
  overflow: hidden;
  white-space: nowrap;
  font-family: sans-serif;
  color: black;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
  transition: background-color 0.1s ease-in-out;
}

.nav__link:hover {
  background-color: #eeeeee;
}

.nav__link--active {
  color: rgb(230, 3, 75);
}
.nav__link:active,
.nav__link:focus,
.nav__link:hover {
  color: rgb(230, 3, 75);
}

.nav__text .t-1 {
  font-size: 1px;
}
.nav__text {
  margin-top: 5px;
}

.selectDiv {
  overflow: hidden;
  background: url(https://cdn2.iconfinder.com/data/icons/food-and-drink-49/100/Waiter-03-512.png);
  background-size: cover;
  border: none;
  margin: 0px;
  padding: 0px;
  position: relative;
  z-index: 100;
}
.selectDefault {
  padding-left: 8px;
}
.selectDiv:hover {
  background-color: transparent !important;
}
.selectDiv select {
  margin: 0px;
  padding: 0px;
  opacity: 0;
  filter: alpha(opacity=0);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  z-index: 101;
}
.selectDiv select:focus {
  outline: none;
}

.selectDefault,
.selectDiv option {
  font-family: arial;
  font-size: 12px;
}
