.ticket table,
tr,
th,
td {
  border-collapse: collapse;
  font-size: 13px !important;
}

.ticket td.description,
td.description {
  width: 50%;
}

.ticket td.quantity,
th.quantity {
  width: 20%;
  max-width: 40px;
  word-break: break-all;
}

.ticket td.price,
th.price {
  width: 30%;
  word-break: break-all;
  color: unset;
}

.ticket .centered {
  text-align: center;
  align-content: center;
}

.ticket {
  width: 95vw;
  margin: 0 auto;
  height: fit-content;
  max-height: 250px;
}

.ticket img {
  max-width: inherit;
  width: inherit;
}

@media print {
  .hidden-print,
  .hidden-print * {
    display: none !important;
  }
}
