@import url("https://fonts.googleapis.com/css2?family=Mate+SC&display=swap");

.logo {
  width: 25vw;
  margin: 20px auto 5px auto;
}
.logo img {
  width: 100%;
}
.slick-arrow {
  font-color: black;
}
.slider h3 {
  font-size: 16px;
  text-align: center;
  font-weight: bold;
}

.slider div:active,
.slider div:visited,
.slider div:focus {
  border: none;
  outline: none;
}
.meal:active {
  opacity: 0.7;
}
.category:active {
  opacity: 0.7;
}

.cart .back {
  margin: 5% 0 0 5%;
  z-index: 5;
  font-size: 30jfpx;
  font-weight: bold;
  color: black;
}
.cart-icon {
  font-size: 26px;
}

.back-cart,
.back-bill {
  position: absolute;
  margin: -5% 0 0 5%;
  z-index: 2;
  font-size: 25px;
  font-weight: bold;
  color: black;
}

/* Meal Detail */
.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  transition: opacity 500ms;
  visibility: hidden;
  opacity: 0;
  z-index: 5000;
}
.overlay.visible {
  visibility: visible;
  opacity: 1;
}

.popup {
  margin: 10% auto;
  padding: 2%;
  background: #fff;
  border-radius: 5px;
  width: 58vw;
  position: relative;
  transition: all 2s ease-in-out;
  border-radius: 20px;
}
.popup label {
  margin: 5% auto;
}
.popup .content {
  text-align: center;
  margin-top: 10%;
}
.popup .close {
  position: absolute;
  top: 0%;
  right: 6%;
  transition: all 200ms;
  font-size: 30px;
  font-weight: bold;
  text-decoration: none;
  color: #333;
}

.popup .close:hover {
  color: #06d85f;
}

.search__container {
  padding-top: 14px;
  width: 90%;
}

.search__title {
  font-size: 22px;
  font-weight: 900;
  text-align: center;
  color: #ff8b88;
}

.search__input {
  width: 100%;
  margin-left: 5%;
  padding: 12px 15px;
  background-color: transparent;
  transition: transform 250ms ease-in-out;
  font-size: 11px;
  color: #575756;
  background-color: transparent;
  /*         background-image: url(http://mihaeltomic.com/codepen/input-search/ic_search_black_24px.svg); */

  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z'/%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 18px 18px;
  background-position: 95% center;
  border-radius: 50px;
  border: 1px solid #575756;
  transition: all 250ms ease-in-out;
  backface-visibility: hidden;
  transform-style: preserve-3d;
}

.search__input::placeholder {
  color: rgba(87, 87, 86, 0.8);
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

.search__input:hover,
.search__input:focus {
  padding: 12px 0;
  outline: 0;
  border: 1px solid transparent;
  border-bottom: 1px solid #575756;
  border-radius: 0;
  background-position: 100% center;
}

.badge:after {
  content: attr(value);
  font-size: 12px;
  color: #fff;
  background: red;
  border-radius: 50%;
  padding: 0 5px;
  position: relative;
  left: -8px;
  top: -10px;
  opacity: 0.9;
}

#myImg {
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
}

#myImg:hover {
  opacity: 0.7;
}

/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 50%; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.9); /* Black w/ opacity */
}

/* Modal Content (Image) */
.modal-content {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
}

/* Caption of Modal Image (Image Text) - Same Width as the Image */
#caption {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
  text-align: center;
  color: #ccc;
  padding: 10px 0;
  height: 150px;
}

/* Add Animation - Zoom in the Modal */
.modal-content,
#caption {
  animation-name: zoom;
  animation-duration: 0.6s;
}

@keyframes zoom {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

/* The Close Button */
.close {
  position: absolute;
  top: 15%;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}

.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}
