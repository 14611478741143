.new-entry-menu {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 200;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  font-size: 0.8vw;
}
.new-entry-container {
  z-index: 250;
  width: 22%;
  height: fit-content;
  background-color: white;
  align-items: center;
  justify-content: center;
  align-content: center;
  padding: 20px;
  overflow: hidden;
}
.details-menu {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 200;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  font-size: 0.8vw;
}
.details-container {
  z-index: 250;
  width: 50%;
  height: fit-content;
  background-color: white;
  align-items: center;
  justify-content: center;
  align-content: center;
  padding: 20px;
  overflow: hidden;
}
.stockMenu-menu {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 200;
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  font-size: 0.8vw;
}
.stockMenu-container {
  z-index: 250;
  width: 40%;
  height: fit-content;
  background-color: white;
  align-items: center;
  justify-content: center;
  align-content: center;
  padding: 20px;
}
.edit-menu {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 200;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  font-size: 0.8vw;
}
.edit-container {
  z-index: 250;
  width: 30%;
  height: fit-content;
  background-color: white;
  align-items: center;
  justify-content: center;
  align-content: center;
  padding: 20px;
  overflow: hidden;
}

.categories-buttons {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
}
.category-button {
  width: 5vw;
  border-radius: 10%;
}
