@font-face {
  font-family: "LetterGothic";
  src: url("./letr45w.ttf") format("truetype");
}
.mate {
  font-family: "LetterGothic", Verdana, Tahoma;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
}
/*
button {
  margin-top: 15%;
  outline: 1.5px solid #5bd627;
}*/
*:not(input, textarea) {
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none; /* Likely future */
}
/* Label styles: style as needed */
label {
  display: block;
  margin: 2em 1em 0.25em 0.75em;
  font-size: 1.25em;
}

/* Container used for styling the custom select, the buttom class adds the bg gradient, corners, etc. */
.dropdown {
  position: relative;
  display: block;
  margin-top: 1.5em;
  padding: 0;
  width: 60vw;
  margin: 0 auto;
  box-sizing: border-box;
  margin: 0px auto;
  border-radius: 100px;
  border: none;
  cursor: pointer;
}

.dropdown:focus,
.dropdown:active,
.dropdown:visited {
  outline: none;
}

/* This is the native select, we're making everything the text invisible so we can see the button styles in the wrapper */
.dropdown select {
  width: 100%;
  margin: 0;
  background: none;
  border: 1px solid transparent;
  outline: none;
  /* Prefixed box-sizing rules necessary for older browsers */
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  /* Remove select styling */
  appearance: none;
  -webkit-appearance: none;
  /* Magic font size number to prevent iOS text zoom */
  font-size: 1.25em;
  /* General select styles: change as needed */
  /* font-weight: bold; */
  color: #444;
  padding: 0.6em 1.9em 0.5em 0.8em;
  line-height: 1.3;
}

.dropdown::after {
  content: "";
  position: absolute;
  width: 9px;
  height: 8px;
  top: 50%;
  right: 1em;
  margin-top: -4px;
  z-index: 2;
  background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 12'%3E%3Cpolygon fill='rgb(0,0,0)' points='8,12 0,0 16,0'/%3E%3C/svg%3E")
    0 0 no-repeat;
  /* These hacks make the select behind the arrow clickable in some browsers */
  pointer-events: none;
}

/* This hides native dropdown button arrow in IE 10/11+ so it will have the custom appearance, IE 9 and earlier get a native select */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .dropdown select::-ms-expand {
    display: none;
  }
  /* Removes the odd blue bg color behind the text in IE 10/11 and sets the text to match the focus style text */
  select:focus::-ms-value {
    background: transparent;
    color: #222;
  }
}

/* Focus style */
select:focus {
  outline: none;
  background-color: transparent;
  color: #222;
}

/* Firefox focus has odd artifacts around the text, this kills that */
select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

option {
  font-weight: normal;
}

.menuButton {
  position: relative !important;
  display: block !important;
  padding: 0 !important;
  width: 90% !important;
  height: 6vh !important;
  box-sizing: content-box !important;
  box-shadow: rgb(0 0 0 / 15%) 2px 2px 10px !important;
  outline: none !important;
  margin: 10% auto 0 auto !important;
  border-radius: 20px !important;
  border: none !important;
  cursor: pointer !important;
}
.menuButton:hover {
  background-color: #5bd627;
}
,
.menuButton:focus,
.menuButton:active,
.menuButton:visited {
  outline: none;
  background-color: #5bd627;
  border: none;
}

.menuButton2 {
  position: relative !important;
  display: block !important;
  padding: 0 !important;
  width: 90% !important;
  height: 6vh !important;
  box-sizing: content-box !important;
  box-shadow: rgb(0 0 0 / 15%) 2px 2px 10px !important;
  outline: none !important;
  margin: 10% auto 0 auto !important;
  border-radius: 20px !important;
  border: none !important;
  cursor: pointer !important;
}
.menuButton2:hover {
  background-color: red;
}
,
.menuButton2:focus,
.menuButton2:active,
.menuButton2:visited {
  outline: none;
  background-color: red;
  border: none;
}

@media (prefers-color-scheme: dark) {
  * {
    background-color: white;
    /* color: black; */
  }
}

.spinner-container {
  width: 100vw;
  height: 100vh;
  position: relative;
  z-index: 50000;
}
.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -20px; /* half width*/
  margin-top: -50px; /* half height*/
}
.payment-logo {
  position: fixed;
  bottom: 0;
  width: 100vw;
}
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99900;
}

.popup-overlay .popup-content {
  position: relative;
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.popup-overlay .close-button {
  position: absolute;
  top: -14px;
  right: 1px;
  font-size: 32px;
  color: black;
  cursor: pointer;
}

.popup-overlay img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}
