@media print {
  html,
  body {
    height: 50px;
  }
}

.ticket table,
tr,
th,
td {
  border-top: 1px solid black;
  border-collapse: collapse;
}

.ticket td.description,
td.description {
  width: 205px;
}

.ticket td.quantity,
th.quantity {
  width: 30px;
  max-width: 50px;
  word-break: break-all;
}

.ticket td.price,
th.price {
  width: 100px;
  word-break: break-all;
  color: unset;
}

.ticket .centered {
  text-align: center;
  align-content: center;
}

.ticket {
  width: 220px;
}

.ticket img {
  max-width: inherit;
  width: inherit;
}

@media print {
  .hidden-print,
  .hidden-print * {
    display: none !important;
  }
}
