.header p {
  font-weight: 600;
  max-height: 55px;
  line-height: 21px;
  cursor: pointer;
}

.image img {
  width: 42.9vw;
  height: 130px;
  object-fit: cover;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.image1 img {
  width: 45vw;
  height: auto;
  object-fit: cover;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.meal .plus {
  text-align: right;
  font-size: 25px;
  color: rgb(230, 3, 75);
}
.multipleRestaurants .meal .plus {
  text-align: right;
  font-size: 15px;
  color: rgb(230, 3, 75);
  margin-top: 30%;
}
.multipleRestaurants .meal .header {
  margin-top: 15%;
  font-weight: bold;
  font-size: 18px;
}
.meal .price {
  color: rgb(100, 100, 100);
}
.cart img {
  width: 25vw;
  height: 25vw;
  object-fit: cover;
  border-radius: 100%;
}
.meal .header {
  margin-top: 15%;
}
.text {
  margin-left: 10px;
}

.meal {
  box-sizing: border-box;
  background-color: rgb(255, 255, 255);
  width: 100% !important;
  padding: 0;
  min-height: 65px;
  margin: 0 auto 5% auto !important;
  animation-duration: 0.7s;
  animation-name: fadeIn;
  border-radius: 12px;
  box-shadow: rgb(0 0 0 / 25%) 5px 5px 25px;
  cursor: pointer;
  position: relative;
}
