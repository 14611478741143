@import url(https://fonts.googleapis.com/css?family=Yanone+Kaffeesatz:400,200);

/* Wrapper Style */

.wrapper {
  width: 100%;
  height: 100vh !important;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  margin: 0px;
}

.wrap {
  width: 75vw;
  height: fit-content;
}
.wrap i {
  font-size: 2.4vw;
}
.fa {
  color: #ffffff;
}
.wrapper div[class^="btn"] {
  float: left;
  margin: 0 1% 1% 0;
  height: 15vh;
  position: relative;
  cursor: pointer;
  transition: all 0.4s ease;
  user-drag: element;
  border: solid 2px transparent;
  text-align: center;
  line-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.wrapper div[class^="btn"]:hover {
  transform: scale(1.2);
  opacity: 0.7;
}
.wrapper div[class^="btn"]:active {
  transform: scale(0.9, 0.9);
}
.btn-big {
  width: 18vw;
}
.btn-small {
  width: 8vw;
}
.last {
  margin-right: 0 !important;
}
.Start {
  color: white;
  font: normal 50px "Yanone Kaffeesatz", sans-serif;
  margin-bottom: 20px;
  cursor: pointer;
  user-select: none;
  transition: all 0.3s ease;
}
.Start:hover {
  text-shadow: 0 0 4px white;
}
.space {
  margin-bottom: 110px;
}
.wrapper .label {
  position: absolute;
  color: white;
  font: 500 1vw sans-serif;
  left: 5%;
  user-select: none;
}
.bottom {
  bottom: 5px;
}
.top {
  top: 5px;
}

.red {
  background: #df0024;
}
.blue {
  background: #00a9ec;
}
.orange {
  background: #ff9000;
}
.green {
  background: #0e5d30;
}
.purple {
  background: #8b0189;
}
.red-light {
  background: #ce4e4e;
}
.wrapper .photo {
  background: url("http://lorempixel.com/200/95/people");
  background-position: -2px -2px;
}
.gray {
  background: #5f5f5f;
  /* animation: flip 6s linear infinite;*/
  transform: rotateX(0deg);
}
.green-bright {
  background: #78d204;
}
.blue-nav {
  background: #25478e;
}
.redish {
  background: #fe0000;
}
.yellow {
  background: #d0d204;
}

.wrapper div[class^="icon"] {
  width: 10%;
  height: 5%;
  margin: 2% auto;
  background-size: 45px 45px;
}

@keyframes flip {
  0% {
    transform: rotateX(0deg);
  }
  15% {
    transform: rotateX(360deg);
  }
  55% {
  }
  100% {
    transform: rotateX(360deg);
  }
}

.photo img {
  top: -4px;
  left: -4px;
  position: absolute;
  opacity: 0;
  animation: fade 8s ease-in-out infinite 8s;
  z-index: 0;
  border: solid 2px transparent;
  transition: all 0.3s ease;
}

.photo img:hover {
  border: solid 2px mintcream;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  60% {
    opacity: 0;
  }
}
