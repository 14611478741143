.is-hidden {
  display: none !important;
}
body.view-company {
  background-attachment: scroll;
  background-color: #000;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}
.dmu_dark-overlay {
  background-color: rgba(0, 0, 0, 0.1);
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}
.language-options {
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 1242px;
  color: #fff;
  display: inline;
  font-size: 14px;
  margin: 24px auto 0;
  padding: 4px 4px 4px 13px;
  text-align: center;
  width: -moz-max-content;
  width: max-content;
}
.language-options i {
  margin-right: 5px;
}
.language-options a:hover {
  text-decoration: underline;
}
.landing-hero {
  color: #fff;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  position: relative;
  z-index: 1000;
}
.landing-hero .hero-logo {
  display: block;
  height: auto;
  margin: 0 auto;
  max-width: 85%;
}
@media screen and (min-width: 800px) {
  .landing-hero .hero-logo {
    max-width: 60%;
  }
}
.landing-hero .action-bar {
  align-items: center;
  display: flex;
  justify-content: space-around;
  margin: 40px auto 0;
  max-width: 540px;
  width: 90%;
}
@media (min-width: 576px) {
  .landing-hero .action-bar {
    width: 100%;
  }
}
.landing-hero .action-bar .action-bar-item {
  align-items: center;
  border-radius: 3000px;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  height: 50px;
  justify-content: space-around;
  padding: 10px;
  text-align: center;
  transition: all 0.3s;
  width: 50px;
}
@media (min-width: 576px) {
  .landing-hero .action-bar .action-bar-item {
    font-size: 24px;
    height: 105px;
    width: 105px;
  }
}
.landing-hero .action-bar .action-bar-item i {
  font-size: 1.8rem;
}
@media (min-width: 576px) {
  .landing-hero .action-bar .action-bar-item i {
    font-size: 2.85rem;
  }
}
.modal-container {
  transition-style: translateY;
  timing-function: ease-out;
  -webkit-overflow-scrolling: touch;
  left: 0;
  overflow: auto;
  position: fixed;
  top: 0;
  transition-duration: 0.25s;
  z-index: 1050;
}
.modal-container.collapsed {
  transform: translateY(110vh);
}
.modal-container.expanded {
  transform: translateY(0);
}
.modal-container {
  background-color: #fff;
  font-weight: 400;
  height: 100%;
  padding: 0;
  text-align: left;
  width: 100%;
}
.modal-container .share-header {
  background-color: #fff;
  box-shadow: 0 20px 16px -6px #fff;
  height: 27px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}
.modal-container .noicon {
  position: relative;
  top: 10px;
}
.modal-container.modal-container-inline.collapsed .share-item {
  margin-right: 0;
  vertical-align: bottom;
}
.modal-container.expanded .share-list-container {
  -webkit-overflow-scrolling: touch;
  height: 100%;
  overflow: auto;
  width: 100%;
}
.share-list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.modal-container.expanded .share-list {
  padding: 45px 18px 85px;
}
.modal-container.expanded .share-item:first-child {
  border-top: 1px solid #e3e3e3;
}
.modal-container.expanded .share-item {
  border-bottom: 1px solid #e3e3e3;
  float: none;
  margin: 0;
  width: 100%;
}
.share-link {
  display: block;
}
.modal-container .share-link,
.share-link {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.modal-container .share-link {
  padding: 10px 0;
  position: relative;
}
.modal-container.modal-container-inline.collapsed .share-link {
  padding: 5px;
}
.icon {
  height: 14px;
  width: 14px;
}
.icon,
.icon-inline {
  background-color: transparent;
  background-repeat: no-repeat;
  border: 0;
  color: transparent;
  display: inline-block;
  font: 0/0 a;
  margin-top: 1px;
  overflow: hidden;
  text-indent: -1000px;
  text-shadow: none;
  vertical-align: text-top;
}
.icon-inline {
  background-position: 0;
  background-size: cover;
  background-size: 100%;
}
.icon-inline.icon-share-facebook-circle,
.icon-inline.icon-share-twitter-circle {
  height: 31px;
  width: 31px;
}
.icon-share-facebook-circle {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath d='M0 15C0 6.716 6.716 0 15 0s15 6.716 15 15-6.716 15-15 15S0 23.284 0 15z' fill='%233B5998'/%3E%3Cpath d='M16 22.5v-8h2.052l.268-2.758h-2.29l.003-1.38c0-.72.063-1.104 1.008-1.104h1.265V6.5h-2.022C13.855 6.5 13 7.838 13 10.087V12h-1.5v2.5H13v8h3z' fill='%23FFF'/%3E%3C/svg%3E");
}
.icon-share-twitter-circle {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath d='M0 15C0 6.728 6.716.022 15 .022S30 6.728 30 15s-6.716 14.978-15 14.978S0 23.273 0 15z' fill='%234099FF'/%3E%3Cpath d='m14.79 12.196.032.518-.524-.063c-1.907-.242-3.573-1.067-4.987-2.453l-.69-.687-.178.508c-.377 1.132-.136 2.327.65 3.13.42.445.325.51-.398.244-.25-.085-.47-.148-.492-.116-.073.074.178 1.037.377 1.417.27.53.827 1.047 1.434 1.354l.513.243-.608.01c-.588 0-.61.01-.546.233.21.688 1.037 1.417 1.96 1.735l.65.222-.567.338a5.892 5.892 0 0 1-2.808.783c-.472.012-.86.054-.86.086 0 .106 1.278.698 2.022.93 2.232.69 4.882.392 6.873-.782 1.414-.836 2.83-2.496 3.49-4.104.355-.857.71-2.422.71-3.173 0-.487.032-.55.62-1.132.345-.338.67-.71.732-.814.105-.2.094-.2-.44-.02-.89.317-1.016.275-.576-.2.326-.34.713-.953.713-1.133 0-.032-.157.02-.335.116-.19.106-.608.264-.922.36l-.566.18-.513-.35c-.283-.19-.68-.4-.89-.464-.535-.15-1.353-.128-1.834.04-1.31.476-2.137 1.703-2.042 3.046z' fill='%23FFF'/%3E%3C/svg%3E");
}
.icon-share-email-circle {
  background: #3b3b3b;
  border: 1px solid #3b3b3b;
  border-radius: 15px;
  display: inline-block;
  height: 30px;
  line-height: 28px;
  text-align: center;
  text-indent: inherit;
  width: 30px;
}
.modal-container .share-desc {
  color: #999;
  display: inline-block;
  font-size: 17px;
  overflow: hidden;
  padding-left: 16px;
  position: absolute;
  text-overflow: ellipsis;
  top: 13px;
  white-space: nowrap;
  width: 85%;
}
.modal-container .share-close-div {
  background-color: #fff;
  bottom: 0;
  box-shadow: 0 -20px 16px -6px #fff;
  height: 70px;
  position: absolute;
  width: 100%;
}
.modal-container .share-close {
  color: #999;
  display: block;
  font-size: 17px;
  font-weight: 700;
  padding: 14px 18px;
  text-decoration: none;
}
.location-container-sorting {
  align-items: center;
  background-color: #3b5d6f;
  color: #fff;
  display: flex;
  font-size: 0.8rem;
  justify-content: center;
  padding: 10px 0;
}
.location-container-sorting h3 {
  margin: 0 25px 0 0;
}
.location-list-container {
  -webkit-overflow-scrolling: touch;
  height: calc(100% - 80px);
  overflow: auto;
  padding-bottom: 100px;
}
.location-list-item {
  border-bottom: 1px solid #bababa;
  padding: 10px 10px 15px;
}
.location-list-item .wrapper {
  margin: 0 auto;
  max-width: 600px;
}
.location-list-item .title {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
}
.location-list-item .title h2 {
  margin: 0 10px 5px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
@media (max-width: 576px) {
  .location-list-item .title h2 {
    font-size: 1rem;
    margin-bottom: 2px;
  }
}
.location-list-item .title .distance {
  font-weight: 700;
  white-space: nowrap;
}
.location-list-item p {
  margin: 0;
}
@media (max-width: 576px) {
  .location-list-item p {
    font-size: 0.9rem;
    line-height: 150%;
  }
}
.location-list-item .contact {
  align-items: center;
  display: flex;
  justify-content: flex-start;
}
.location-list-item .contact a {
  border: 1px solid #0070c9;
  border-radius: 4px;
  color: #0070c9;
  padding: 5px;
  text-decoration: none;
}
.location-list-item .contact a.whatsapp {
  border-color: #128c7e;
  color: #128c7e;
}
.location-list-item .navigation-items {
  align-items: center;
  display: flex;
  font-weight: 700;
}
.location-list-item .navigation-items a {
  border: none;
}
.primary-announcement-container {
  background-color: #fff;
  margin: 0 auto 40px;
  padding: 2px 10px;
}
.primary-announcement-container .row {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.primary-announcement-container .row .left-part {
  align-items: center;
  display: flex;
  justify-content: flex-start;
}
.primary-announcement-container .row .left-part .icon {
  border-radius: 8px;
  height: 40px;
  overflow: hidden;
  width: 40px;
}
.primary-announcement-container .row .left-part .app-name {
  font-size: 1.2rem;
  font-weight: 600;
  margin-left: 5px;
}
.primary-announcement-container .btn-action {
  background-color: #0095f6;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  margin-top: 3px;
  padding: 6px 12px;
}
@media (display-mode: standalone) {
  #a2h-section {
    display: none;
  }
}
.hidden {
  display: none !important;
}
.menu-announcements {
  background-color: #fff;
  border-radius: 10px;
  color: #000;
  margin: 0 auto;
  max-width: 90%;
  padding: 10px 5px;
}
@media (min-width: 576px) {
  .menu-announcements {
    max-width: 600px;
    padding: 20px 20px 30px;
  }
}
.menu-announcements h4 {
  font-size: 1.1rem;
  margin: 0;
  text-align: center;
}
@media (min-width: 576px) {
  .menu-announcements h4 {
    font-size: 1.35rem;
    margin-top: 0;
  }
}
.menu-announcements p {
  font-size: 0.95rem;
  line-height: 130%;
  margin: 0;
  text-align: center;
}
.container {
  margin: 0 auto;
  width: 95%;
}
.bundle-content {
  margin-top: 40px;
}
.bundle-header {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  width: 90%;
}
.bundle-header h3 {
  color: #2d3436;
  font-size: 1.6rem;
  letter-spacing: -0.02rem;
  margin: 0;
}
.bundle-container {
  -webkit-overflow-scrolling: touch;
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin-top: 10px;
  overflow: auto;
  overflow-y: hidden;
  padding: 0 5%;
}
.bundle-container::-webkit-scrollbar {
  display: none;
}
.bundle-item {
  align-items: flex-end;
  background: #232526;
  background: linear-gradient(90deg, #414345, #232526);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 7px;
  color: #fff;
  display: flex;
  font-size: 1.35rem;
  font-weight: 600;
  height: 140px;
  margin-right: 5%;
  min-width: 224px;
  overflow: hidden;
  padding: 10px;
  text-shadow: 1px 1px 10px #000, 1px 1px 15px rgba(0, 0, 0, 0.9),
    1px 1px 20px rgba(0, 0, 0, 0.8);
}
.promo-badge {
  background-color: #fef3c7;
  border-radius: 1242px;
  color: #b45309;
  font-size: 0.75rem;
  font-weight: 600;
  letter-spacing: 0.025em;
  line-height: 1rem;
  padding: 0.125rem 0.5rem;
  text-shadow: none;
}
.bundle-title {
  font-size: 1.6rem;
  margin-bottom: 0;
  padding-bottom: 0;
}
.bundle-desc {
  margin-top: 5px;
}
.subgroup-container {
  margin-top: 15px;
}
.subgroup-container:first-child {
  margin-top: 0;
}
.subgroup-title {
  font-size: 1.3rem;
  margin: 0 0 1%;
}
.bundle-selectbox {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E"),
    linear-gradient(180deg, #fff 0, #e5e5e5);
  background-position: right 0.7em top 50%, 0 0;
  background-repeat: no-repeat, repeat;
  background-size: 0.65em auto, 100%;
  border: 1px solid #aaa;
  border-radius: 0.5em;
  box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
  box-sizing: border-box;
  color: #444;
  display: block;
  font-family: sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.3;
  margin: 0;
  max-width: 100%;
  padding: 0.6em 1.4em 0.5em 0.8em;
  width: 100%;
}
.bundle-selectbox::-ms-expand {
  display: none;
}
.bundle-selectbox:hover {
  border-color: #888;
}
.bundle-selectbox:focus {
  border-color: #aaa;
  box-shadow: 0 0 1px 3px rgba(59, 153, 252, 0.7);
  box-shadow: 0 0 0 3px -moz-mac-focusring;
  color: #222;
  outline: none;
}
.bundle-selectbox option {
  font-weight: 400;
}
:root:lang(ar) .bundle-selectbox,
:root:lang(iw) .bundle-selectbox,
[dir="rtl"] .bundle-selectbox {
  background-position: left 0.7em top 50%, 0 0;
  padding: 0.6em 0.8em 0.5em 1.4em;
}
.bundle-selectbox:disabled,
.bundle-selectbox[aria-disabled="true"] {
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22graytext%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E"),
    linear-gradient(180deg, #fff 0, #e5e5e5);
  color: graytext;
}
.bundle-selectbox:disabled:hover,
.bundle-selectbox[aria-disabled="true"] {
  border-color: #aaa;
}
#price-container {
  font-size: 1.3rem;
  margin-top: 20px;
  text-align: center;
}
.bundle-submit {
  background: #00c6ff;
  background: linear-gradient(0deg, #0072ff, #00c6ff);
  border: none;
  border-radius: 1242px;
  border-radius: 0.5em;
  color: #fff;
  font-size: 1.2rem;
  font-weight: 600;
  margin-top: 40px;
  padding: 10px;
  width: 100%;
}
.price-normal {
  font-weight: 500;
}
.bundle-submit,
.bundle-submit.disabled {
  opacity: 1;
}
.menu-sections-panel {
  -webkit-overflow-scrolling: touch;
  display: flex;
  overflow-x: overlay;
  overflow-y: hidden;
  position: sticky;
  top: 0;
  white-space: nowrap;
  width: 100%;
  z-index: 900;
}
.menu-sections-panel .menu-sections-panel-item {
  font-weight: 800;
  padding: 10px 15px;
}
.menu-sections-panel.panel-style-2 {
  padding: 10px 0;
}
.menu-sections-panel.panel-style-2 .menu-sections-panel-item {
  align-items: flex-end;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 5px;
  display: flex;
  height: 45px;
  justify-content: center;
  margin-right: 10px;
  max-width: 150px;
  min-width: 90px;
  padding: 10px 15px 3px;
}
.menu-sections-panel.panel-style-2 .menu-sections-panel-item:first-child {
  margin-left: 10px;
}
.menu-sections-panel.panel-style-2 .menu-sections-panel-item span {
  display: inline-block;
  font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial,
    sans-serif;
  font-size: 0.8rem;
  letter-spacing: -0.033rem;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
  white-space: nowrap;
  width: 95%;
}
::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.3);
}
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.menu-chart {
  margin: 40px auto 0;
  width: 100%;
}
.menu-chart h3 {
  color: #2d3436;
  font-size: 1.6rem;
  letter-spacing: -0.02rem;
  margin: 20px auto 10px;
  width: 90%;
}
.menu-chart .top-chart {
  -webkit-overflow-scrolling: touch;
  align-items: center;
  display: flex;
  justify-content: flex-start;
  overflow: auto;
  overflow-y: hidden;
  padding: 0 5%;
}
.menu-chart .top-chart::-webkit-scrollbar {
  display: none;
}
.menu-chart .top-chart .chart-item {
  align-items: flex-start;
  background: #232526;
  background: linear-gradient(to bottom right, #414345, #232526);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 9px;
  color: #fff;
  display: flex;
  flex-direction: column;
  font-weight: 600;
  height: 120px;
  justify-content: flex-end;
  line-height: 120%;
  margin-right: 10px;
  min-width: 120px;
  overflow: hidden;
  padding: 7px;
  position: relative;
  text-shadow: 1px 1px 10px #1c2e58, 1px 1px 15px #1c2e58, 1px 1px 20px #1c2e58;
}
.menu-chart .top-chart .chart-item h4 {
  font-size: 1rem;
  margin: 0;
}
.menu-chart .top-chart .chart-item h4.item-w-variation {
  font-size: 0.8rem;
}
.menu-chart .top-chart .chart-item span {
  display: block;
  font-family: HelveticaNeue, Arial, Tahoma, sans-serif;
  font-size: 1rem;
  font-weight: 800;
  letter-spacing: 1px;
  line-height: 110%;
}
.menu-chart .top-chart .chart-item .btn-order-fav {
  align-items: center;
  background-color: #44b0ff;
  border: none;
  border-radius: 0;
  border-bottom-left-radius: 10px;
  color: #fff;
  display: flex;
  font-size: 1rem;
  justify-content: center;
  margin: 0;
  padding: 6px 8px;
  position: absolute;
  right: 0;
  top: 0;
}
.menu-chart .top-chart .chart-item .btn-order-fav.in-cart {
  background-color: #1abc9c;
}
.hero-shadow {
  line-height: 120%;
  text-shadow: 0 0 15px #000;
}
.sidepage-hero {
  background-image: linear-gradient(0deg, #414345, #232526);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 40vh;
  padding: 32px 0;
  position: relative;
  text-align: center;
}
@media screen and (min-width: 1024px) {
  .sidepage-hero {
    min-height: 60vh;
  }
}
.sidepage-hero__link {
  z-index: 10;
}
.sidepage-hero__logo {
  align-items: center;
  color: #fff;
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: space-evenly;
  padding: 20px;
  position: absolute;
  text-align: center;
  width: 100%;
  z-index: 1;
}
.sidepage-hero__logo--l {
  margin-top: 24px;
  max-width: 600px;
  width: 50%;
}
@media screen and (min-width: 1024px) {
  .sidepage-hero__logo--l {
    width: 30%;
  }
}
.sidepage-hero__logo--s {
  max-width: 500px;
  width: 30%;
  z-index: 10;
}
@media screen and (min-width: 1024px) {
  .sidepage-hero__logo--s {
    width: 17%;
  }
}
.sidepage-hero__title {
  color: #fff;
  font-size: 2rem;
  background: transparent;
  line-height: 130%;
  margin: 0 0 20px;
  text-shadow: 1px 1px 15px rgba(0, 0, 0, 0.9), 1px 1px 20px rgba(0, 0, 0, 0.8);
  z-index: 10;
}
@media screen and (min-width: 1024px) {
  .sidepage-hero__title {
    font-size: 4rem;
  }
}
.sidepage-hero__actions {
  color: #fff;
  display: flex;
  font-size: 1.5rem;
  justify-content: center;
  margin-top: 24px;
  width: 100%;
}
@media screen and (min-width: 1024px) {
  .sidepage-hero__actions {
    font-size: 2.3rem;
  }
}
.sidepage-hero__actions__item {
  background-color: rgba(0, 0, 0, 0.65);
  border-radius: var(--dm-rounded-full);
  box-shadow: var(--dm-shadow-action);
  line-height: 30px;
  margin-right: 20px;
  min-height: 50px;
  min-width: 50px;
  padding: 10px;
  text-align: center;
}
.sidepage-hero__actions__item.navigate {
  color: var(--dm-color-action-navigate);
}
.sidepage-hero__actions__item.call {
  color: var(--dm-color-action-call);
}
.sidepage-hero__actions__item.share {
  color: var(--dm-color-action-share);
}
.sidepage-hero__actions__item:last-child {
  margin-right: 0;
}
@media screen and (min-width: 1024px) {
  .sidepage-hero__actions__item {
    line-height: 60px;
    margin-right: 20px;
    min-height: 80px;
    min-width: 80px;
  }
}
.sidepage-hero__btn {
  background-color: rgba(0, 0, 0, 0.4);
  border: 1px solid #fff;
  border-radius: 1242px;
  color: #fff;
  display: inline-block;
  font-weight: 600;
  margin: 0 auto;
  padding: 4px 25px;
  width: -moz-max-content;
  width: max-content;
  z-index: 10;
}
@media screen and (min-width: 1024px) {
  .sidepage-hero__btn {
    font-size: 1.4rem;
    padding: 10px 30px;
  }
}
.menu-header {
  display: flex;
  justify-content: space-between;
  margin: 20px auto 10px;
  width: 90%;
}
.menu-header h3 {
  color: #2d3436;
  font-size: 1.6rem;
  letter-spacing: -0.02rem;
  margin: 0;
}
.section-desc {
  font-size: 1rem;
  line-height: 140%;
  margin: 0 10px;
  text-align: center;
}
.hero {
  bottom: 0;
  left: 0;
  margin-bottom: 1vh;
  min-height: 100vh;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1;
}
.hero,
.hero .slide {
  background-attachment: scroll;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}
.hero .slide {
  height: 100%;
  opacity: 0;
  position: absolute;
  transition: opacity 1s;
}
.slide.is-shown {
  opacity: 1;
}
.dm-action-btn {
  align-items: center;
  background: linear-gradient(to bottom right, #00c6ff, #0072ff);
  border: none;
  border-radius: 0.75rem;
  bottom: 15px;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.02),
    0 6.7px 5.3px rgba(0, 0, 0, 0.028), 0 12.5px 10px rgba(0, 0, 0, 0.035),
    0 22.3px 17.9px rgba(0, 0, 0, 0.042), 0 41.8px 33.4px rgba(0, 0, 0, 0.05),
    0 100px 80px rgba(0, 0, 0, 0.07);
  color: #fff;
  display: flex;
  flex-direction: column;
  font-size: 0.85rem;
  font-weight: 700;
  height: 75px;
  justify-content: center;
  position: fixed;
  right: 15px;
  width: 75px;
  z-index: 100;
}
#number-badge {
  background-color: #ea2027;
  border-radius: 1242px;
  box-shadow: 0 0 2.2px rgba(0, 0, 0, 0.3);
  color: #fff;
  font-size: 0.8rem;
  padding: 1px 10px;
  position: absolute;
  right: -7px;
  top: -5px;
  transform: scale(0.9);
}
.featured-item {
  background-color: #fff;
  border-radius: 7px;
  margin-bottom: 20px;
  padding: 10px;
}
.featured-item-image {
  aspect-ratio: 4/3;
  border-radius: 4px;
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
}
.sticky-header {
  align-items: center;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  background-color: #22252a;
  background: rgba(0, 0, 0, 0.6);
  border-bottom: 1px solid hsla(0, 0%, 49%, 0.5);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.4);
  color: #fff;
  display: flex;
  height: 44px;
  justify-content: space-between;
  left: 0;
  padding: 5px 0;
  position: fixed;
  right: 0;
  top: 0;
  transform: translateZ(0);
  transition: all 0.3s ease;
  z-index: 1000;
}
.sticky-header.not-showing {
  transform: translate3d(0, -60px, 0);
}
.sticky-header__content {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding-left: 5%;
  width: 100%;
}
.sticky-header__content__side {
  align-items: center;
  display: flex;
  white-space: nowrap;
}
.sticky-header__title {
  font-size: 19px;
  font-weight: 600;
  height: 43px;
  line-height: 44px;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.sticky-header__content__nav-btn {
  font-size: 19px;
}
.sticky-header__actions {
  font-size: 22px;
  line-height: 44px;
}
.sticky-header__actions__item {
  padding: 10px;
}
.sticky-header__actions__item.navigate {
  color: var(--dm-color-action-navigate);
}
.sticky-header__actions__item.call {
  color: var(--dm-color-action-call);
}
.sticky-header__actions__item.share {
  color: var(--dm-color-action-share);
}
.sections-content {
  margin: 40px auto 0;
}
.menu-title {
  color: #2d3436;
  font-size: 1.6rem;
  letter-spacing: -0.02rem;
  margin: 0 auto 10px;
  width: 90%;
}
.menu-grid {
  grid-gap: 15px;
  display: grid;
  grid-auto-flow: row dense;
  grid-auto-rows: 100px;
  grid-template-columns: 1fr 1fr;
  margin: 0 auto 20px;
  max-width: 90%;
  position: relative;
}
@media screen and (min-width: 1025px) {
  .menu-grid {
    grid-gap: 30px;
    max-width: 960px;
  }
}
.menu-grid-item {
  background-image: linear-gradient(0deg, #414345, #232526);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
  font-weight: 600;
  overflow: hidden;
  position: relative;
}
@media screen and (min-width: 1024px) {
  .menu-grid-item {
    border-radius: 15px;
  }
}
.menu-grid-item .black-box {
  background-color: rgba(0, 0, 0, 0.1);
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.menu-grid-item.style-2 {
  grid-column: span 2;
}
.menu-grid-item.style-3 {
  grid-row: span 2;
}
.menu-grid-item.style-3 .text-box {
  font-size: 1.2rem;
}
.menu-grid-item.style-4 {
  grid-column: span 2;
  grid-row: span 2;
}
.menu-grid-item.style-4 .text-box {
  font-size: 1.4rem;
}
.menu-grid-item.style-5 {
  grid-row: span 3;
}
.menu-grid-item.style-5 .text-box {
  font-size: 1.1rem;
}
.menu-grid-item.style-6 {
  grid-column: span 2;
  grid-row: span 3;
}
.menu-grid-item.style-6 .text-box {
  font-size: 1.7rem;
}
.menu-grid-item .text-box {
  background: transparent;
  color: white;
  font-size: 1.4rem;
  font-weight: 600;
  width: 100%;
  line-height: 120%;
  padding: 10px;
  position: absolute;
  top: 20%;
  left: 0;
  text-shadow: 1px 1px 13px rgba(0, 0, 0, 0.9), 1px 1px 20px rgba(0, 0, 0, 0.8);
}
.sidebar-open-btn {
  color: #fff;
  padding: 20px 10px;
  position: absolute;
  top: 0;
  z-index: 100;
}
.sidebar-close-btn {
  left: 0;
  padding: 10px;
  position: absolute;
  top: 0;
}
.sidebar {
  transition-style: translateX;
  timing-function: ease-out;
  background: #000;
  background: linear-gradient(180deg, #434343, #000);
  color: #fff;
  display: flex;
  flex-direction: column;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  transform: translateX(-270px);
  transition-duration: 0.35s;
  width: 270px;
  z-index: 1000;
}
.sidebar.revealed {
  transform: translateX(0);
}
.sidebar .sidebar-header {
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.2);
  padding: 10px;
  position: sticky;
  top: 0;
}
.sidebar .sidebar-header .sidebar-title {
  display: block;
  margin-bottom: 20px;
  margin-top: 30px;
}
.sidebar .sidebar-header .sidebar-title img {
  display: block;
  margin: 0 auto;
  width: 60%;
}
.sidebar .sidebar-content {
  overflow-scrolling: touch;
  flex-grow: 2;
  overflow-y: scroll;
}
.sidebar .sidebar-nav-item {
  align-items: center;
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.2);
  display: flex;
  padding: 10px;
}
.sidebar .sidebar-nav-item .thumb {
  background-image: linear-gradient(0deg, #414345, #232526);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 8px;
  height: 50px;
  margin-right: 7px;
  min-width: 50px;
}
.sidebar .sidebar-nav-item .title {
  font-size: 20px;
}
.items-container {
  margin: 20px 5% 0;
}
.section-subtitle {
  background-color: #edf2f7;
  color: var(--dm-color-heading2);
  font-size: var(--dm-fontsize-lg);
  letter-spacing: var(--dm-letterspacing-tight);
  margin: 48px 0 0;
  padding: 4px 0;
  top: 44px;
}
.section-subtitle-description {
  font-size: var(--dm-fontsize-s);
  line-height: 140%;
  margin: 2px 0 16px;
}
.item-box.is-featured {
  background-color: var(--dm-color-featured-bg);
  border-radius: var(--dm-rounded-r);
  flex-direction: column;
  margin: 25px 0;
  overflow: hidden;
}
.item-box.is-featured .item-image {
  aspect-ratio: 4/3;
  border-radius: 0;
  margin-right: 0;
  width: 100%;
}
.item-box.is-featured .item-content {
  padding: 16px 12px;
  width: 100%;
}
.item-box {
  align-items: flex-start;
  display: flex;
  margin-bottom: var(--dm-itembox-mb);
}
.item-box.item-box-image {
  margin-bottom: 24px;
}
.item-box .item-image {
  aspect-ratio: 1/1;
  border-radius: var(--dm-rounded-s);
  flex-shrink: 0;
  margin-right: 8px;
  -o-object-fit: cover;
  object-fit: cover;
  overflow: hidden;
  width: 60px;
}
.item-box .item-content {
  flex-grow: 1;
}
.item-box .item-content .primary-content .primary-head {
  align-items: baseline;
  display: flex;
}
.item-box .item-content .primary-content .primary-head .primary-headtext {
  align-items: center;
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
}
.item-box
  .item-content
  .primary-content
  .primary-head
  .primary-headtext
  .primary-title {
  color: #273c75;
  font-family: proxima-nova, BlinkMacSystemFont, -apple-system, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  --dm-color-background: #edf2f7;
  font-size: 1.3rem;
  font-weight: 600;
  letter-spacing: 0rem;
  line-height: 160%;
  margin: 0 5px 0 0;
}
.item-box
  .item-content
  .primary-content
  .primary-head
  .primary-headtext
  .primary-title.w-image {
  margin: -24px 5px 0 0;
}
.item-box .item-content .primary-content .primary-head .primary-headtext .tags {
  display: flex;
  flex-wrap: wrap;
}
.item-box
  .item-content
  .primary-content
  .primary-head
  .primary-headtext
  .tags
  .tag {
  background-color: var(--dm-color-tag-bg);
  border-radius: 3px;
  color: var(--dm-color-tag-text);
  font-size: 0.7rem;
  font-weight: 600;
  line-height: 100%;
  margin-right: 5px;
  padding: 3px 5px;
}
.item-box
  .item-content
  .primary-content
  .primary-head
  .primary-headtext
  .tags
  .tag:last-child {
  margin-right: 0;
}
.item-box .item-content .primary-content p {
  font-family: proxima-nova, BlinkMacSystemFont, -apple-system, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  --dm-color-background: #edf2f7;
  font-size: 0.8rem;
  line-height: 140%;
  margin: 0;
}
.item-box .item-content .secondary-content {
  margin-top: 8px;
}
.item-box .item-content .secondary-content .variation-item {
  align-items: flex-start;
  display: flex;
  padding-left: 20px;
}
.item-box .item-content .secondary-content .variation-item .variation-name {
  flex-grow: 1;
}
.item-box .price-box {
  align-items: center;
  display: flex;
}
.item-box .price-box .price-content {
  align-items: baseline;
  display: flex;
  justify-content: flex-end;
}
.item-box .price-content.--item {
  color: #273c75;
}
.item-box .price-content.--item .price {
  font-size: 1.3rem;
  font-weight: 600;
  letter-spacing: 0rem;
}
.item-box .price-content.--item .currency {
  font-size: 1rem;
  margin-left: 1px;
}
.item-box .price-content.--variation .price {
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0rem;
}
.item-box .price-content.--variation .currency {
  font-size: 0.7rem;
  margin-left: 1px;
}
.item-box .btn-add-item {
  aspect-ratio: 1/1;
  background-color: #44b0ff;
  border-radius: 3px;
  color: v#fff;
  display: grid;
  flex-shrink: 0;
  margin-left: 3px;
  place-items: center;
  width: 20px;
}
.related-box {
  background: #0f2027;
  background: linear-gradient(0deg, #2c5364, #203a43, #0f2027);
  border-radius: 10px;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.02),
    0 6.7px 5.3px rgba(0, 0, 0, 0.028), 0 12.5px 10px rgba(0, 0, 0, 0.035),
    0 22.3px 17.9px rgba(0, 0, 0, 0.042), 0 41.8px 33.4px rgba(0, 0, 0, 0.05),
    0 100px 80px rgba(0, 0, 0, 0.07);
  color: #fff;
  margin: 80px auto;
  padding: 10px;
  width: 90%;
}
.related-box h3 {
  font-size: 1.6rem;
  line-height: 105%;
  margin: 0 0 15px;
}
.related-box h3 small {
  font-size: 1.1rem;
}
.related-box-item {
  align-items: center;
  display: flex;
  margin-bottom: 10px;
}
.related-box-item-thumb {
  background-position: 50%;
  background-size: cover;
  border-radius: 10px;
  height: 50px;
  margin-right: 10px;
  width: 50px;
}
.related-box-item-text {
  font-size: 1.1rem;
  font-weight: 600;
  text-shadow: 2px 2px 3px #161616;
}
.footer-dmenu {
  line-height: 100%;
  margin-top: 50px;
  margin-bottom: 20px;
  text-align: center;
}
.social-footer {
  display: flex;
  justify-content: space-around;
  margin: 0 auto 40px;
  max-width: 300px;
}
.social-footer .instagram i {
  color: #c13584;
}
.social-footer .twitter i {
  color: #00acee;
}
.social-footer .facebook i {
  color: #3b5998;
}
.social-footer .youtube i {
  color: red;
}
.social-footer .foursquare i {
  color: #f94877;
}
.social-footer .tripadvisor i {
  color: #00af87;
}
.social-footer i {
  transition: all 0.15s;
}
.social-footer i:hover {
  color: #2d3436;
}
.btn-order {
  align-items: center;
  background-color: #44b0ff;
  border: none;
  border-radius: 5px;
  color: #fff;
  display: flex;
  font-size: 1rem;
  justify-content: center;
  margin: 0;
  padding: 2px 8px;
}
.btn-order.in-cart {
  background-color: #1abc9c;
}
#nocart-content {
  align-items: center;
  color: #a0144b;
  display: flex;
  font-size: 1.6rem;
  font-weight: 600;
  justify-content: center;
  line-height: 140%;
  margin: 20px auto;
  text-align: center;
}
.modal-close {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}
.btn-close-shopping-modal {
  color: #2997ff;
  font-weight: 800;
}
.btn-clear-cart,
.btn-close-shopping-modal {
  -webkit-appearance: none;
  background-color: transparent;
  border: none;
}
.btn-clear-cart {
  color: #e52535;
}
.cart-list {
  font-size: 0.95rem;
  margin-bottom: 10px;
  width: 100%;
}
.cart-list th:nth-child(2),
.cart-list th:nth-child(3),
.cart-list tr td:nth-child(2) {
  text-align: center;
}
.cart-list tr td:nth-child(3) {
  text-align: right;
}
.cart-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.location-stack {
  font-size: 0.95rem;
  padding: 5px;
  width: 100%;
}
.order-extras {
  align-items: baseline;
  display: flex;
  font-size: 0.85rem;
  margin-bottom: 10px;
}
.order-extras h3 {
  font-size: 0.95rem;
  margin: 3px 0;
  min-width: 100px;
}
.order-extras .flex-cols {
  display: flex;
  flex-direction: column;
}
.order-extras .field-w-extra {
  display: flex;
}
.order-extras input[type="tel"],
.order-extras input[type="text"] {
  border: 1px solid gray;
  border-radius: 4px;
  font-size: 0.95rem;
  min-width: 200px;
  padding: 5px;
}
.order-extras input[type="tel"].order-extra-input-text,
.order-extras input[type="text"].order-extra-input-text {
  font-size: inherit;
  margin-left: 4px;
  min-width: inherit;
  padding: 0 3px;
}
#order-total {
  text-align: center;
}
#address-container p {
  color: #d63031;
  margin-bottom: 10px;
  margin-top: 10px;
  text-align: center;
}
.submit-btn-container {
  margin-bottom: 100px;
}
.btn-send-order,
.submit-btn-container p {
  text-align: center;
}
.btn-send-order {
  -webkit-appearance: none;
  border: none;
  border-radius: 1242px;
  display: block;
  font-size: 1rem;
  font-weight: 800;
  margin-bottom: 10px;
  margin-top: 10px;
  padding: 10px;
  width: 100%;
}
.btn-send-order:hover {
  background-color: #00a98f;
}
.btn-send-order.whatsapp {
  background-color: #34bf49;
  color: #fff;
}
.btn-send-order.telegram {
  background-color: #188eff;
  color: #fff;
}
.btn-send-order.call {
  background-color: #ea4335;
  color: #fff;
}
.wiretransfer-container {
  background-color: #f1f2f6;
  border-radius: 8px;
  color: #2f3542;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  padding: 10px;
  width: 100%;
}
.wiretransfer-container h4,
.wiretransfer-container h5 {
  margin: 0;
}
.wiretransfer-container h5 {
  font-size: 0.95rem;
}
.wiretransfer-container .title {
  text-align: center;
}
textarea.order-modal {
  border-radius: 4px;
  font-size: 1.1rem;
  padding: 5px;
  width: 100%;
}
#discount-pickup-teaser {
  background-color: #fef3c7;
  border-radius: 1242px;
  color: #b45309;
  display: inline-block;
  font-weight: 600;
  line-height: 100%;
  padding: 4px 10px;
}
#discount-togo-teaser {
  display: inline-block;
  font-weight: 700;
  line-height: 100%;
  padding: 4px;
}
.promo-container {
  background-color: rgba(0, 0, 0, 0.8);
  bottom: 0;
  display: grid;
  height: 100vh;
  left: 0;
  place-items: center;
  position: fixed;
  right: 0;
  top: 0;
  width: 100vw;
  z-index: 100000000000;
}
.promo-image-box {
  box-shadow: 0 5px 35px rgba(0, 0, 0, 0.45);
  margin: 8%;
  max-height: 80vh;
  max-width: 80%;
  -o-object-fit: contain;
  object-fit: contain;
}
.btn-promo-hide {
  color: #fff;
  cursor: pointer;
  padding: 15px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1000;
}
.promo-image-box img {
  max-width: 100%;
}
